import React from "react";
import tick from "./ticktick.png";
import "./Thankyou.css";
const Thankyou = () => {
  // Thankyou component rendering thankyou message with an image
  // Thnakyou component loads up when the TrialPage form submisson is done
  return (
    <>
      <div className="thanks_div">
        <h1 className="thanks">Thank You </h1>
        <img className="tick" src={tick} alt="" />
        <p className="thanks_p">
          Your response has been submitted, we'll be contacting you shortly !
        </p>
      </div>
    </>
  );
};

export default Thankyou;
