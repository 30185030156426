import React from "react";
import axios from "axios";
import Loaderpage from "../../components/LoaderPage";
import "../../App.css";

class About3 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      about3s: [],
      loading: false,
    };
  }
  componentDidMount() {
    this.setState({ loading: false });
    // https://cms.deepthought.education:5055
    axios
      .get(`${process.env.REACT_APP_PROD_HOST}/about/about3s`)
      .then((res) => {
        const about3s = res.data;
        // console.log(about3s);
        this.setState({ about3s, loading: true });
      });
  }
  render() {
    return (
      <>
        <section className="leadership">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-12">
                <h1 className="txt-left main-head">Leadership Team</h1>
                <p className="leadership-para">
                  Meet the people behind Agile Recruitech, it takes a group of
                  enthusiastic leaders and innovators to make things happen
                </p>
              </div>
              {this.state.about3s &&
                this.state.about3s.map((about3, index) => {
                  return (
                    <>
                      {this.state.loading ? (
                        <div
                          className="Leadercontent content"
                          // className="col-sm- 6 col-md-4 col-lg-3"
                          key={index}
                        >
                          <div className="content-overlay"></div>
                          <img id="leaderImage" src={about3.image} />
                          {/* <img src="/assets/images/team/team.png" /> */}
                          <h5 id="LeaderHeading">{about3.name}</h5>
                          <p id="Designation">{about3.designation}</p>
                          <hr id="hrLine" />
                          <p id="desc">
                            {about3.aboutleader
                              ? about3.aboutleader
                              : "Please Add something About Leader!"}
                          </p>
                          <div className="content-details fadeIn-top">
                            {about3.twitter === "" ? (
                              <></>
                            ) : (
                              <a
                                href={about3.twitter}
                                className="team-social"
                                target="_blank"
                              >
                                <i className="fab fa-twitter"></i>
                              </a>
                            )}
                            {about3.facebook === "" ? (
                              <></>
                            ) : (
                              <a
                                href={about3.facebook}
                                className="team-social"
                                target="_blank"
                              >
                                <i className="fab fa-facebook-f"></i>
                              </a>
                            )}
                            {about3.google === "" ? (
                              <></>
                            ) : (
                              <a
                                href={about3.google}
                                target="_blank"
                                className="team-social"
                              >
                                <i className="fab fa-google-plus-g"></i>
                              </a>
                            )}
                            {about3.linkedIn === "" ||
                            about3.linkedIn === undefined ? (
                              ""
                            ) : (
                              <a
                                href={about3.linkedIn}
                                target="_blank"
                                className="team-social"
                              >
                                <i className="fab fa-linkedin"></i>
                              </a>
                            )}
                          </div>
                        </div>
                      ) : (
                        <div className="divLoader1">
                          <Loaderpage />
                        </div>
                      )}
                    </>
                  );
                })}
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default About3;
