import React, { createRef } from "react";

import { Helmet } from "react-helmet";
import Carousel, { consts } from "react-elastic-carousel";
import axios from "axios";
import Loaderpage from "./LoaderPage";
import Header from "./header";
import Footer from "./footer";
import Home1 from "../components/home/home1";
import Home2 from "../components/home/home2";
import Home3 from "../components/home/home3";
import Home4 from "../components/home/home4";
import Testimonial from "./home/testimonial";
import "../App.css";
import ReactPlayer from "react-player";
import FotterForm from "./fotterForm";
// import YouTubePlayer from 'react-player/lib/players/YouTube'

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      home4s: [],
      home2s: [],
      loading: false,
      count: undefined,
    };
    this.handlePlayVideo = this.handlePlayVideo.bind(this);
    this.myarrow = this.myarrow.bind(this);
    this.crousalRef = createRef();
    this.videoRef = createRef();
  }
  // componentDidMount() {

  // }
  componentDidMount() {
    this.setState({ loading: false });
    axios
      .get(`${process.env.REACT_APP_PROD_HOST}/home/home1_1s`)
      .then((res) => {
        const home1_1s = res.data;
        // console.log(home1_1s);
        this.setState({ home1_1s, loading: true });
      });
    axios
      .get(
        `${process.env.REACT_APP_PROD_HOST}/home/update_home1/608b912efcc7860015dce5b1`
      )
      .then((res) => {
        // console.log(res.data);
        const home1_1 = {
          title: res.data.title,
          subtitle: res.data.subtitle,
        };
        // console.log(home1_1.title);
        this.setState({
          title: home1_1.title,
          subtitle: home1_1.subtitle,
        });
      });
    axios.get(`${process.env.REACT_APP_PROD_HOST}/home/home4s`).then((res) => {
      const home4s = res.data;
      // console.log(home4s);
      this.setState({ home4s });
    });
  }
  handlePlayVideo = (prev, next) => {
    var videos = document.querySelectorAll("iframe");
    videos.forEach((i) => {
      const source = i.src;
      i.src = "";
      i.src = source;
    });
    // if (prev?.item?.id) {
    //   document.getElementById(prev.item.id).pause()
    // }
  };


  myarrow = ({ type, onClick, isEdge }) => {
    if (isEdge === true) {
      if (type === "PREV") {
        var pointer = <i class="fa fa-chevron-left ArrowLeftIcon"></i>;
      } else {
        pointer = <i class="fa fa-chevron-right ArrowLeftIcon"></i>;
      }
    } else {
      if (type === "PREV") {
        pointer = <i class="fa fa-chevron-left ArrowIcon"></i>;
      } else {
        pointer = <i class="fa fa-chevron-right ArrowIcon"></i>;
      }
    }
    return (
      <button onClick={onClick} disabled={isEdge}>
        {pointer}
      </button>
    );
  };

  render() {
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Agile Recruitech - Home</title>
          <link rel="canonical" href="https://mysite.com/example" />
          <link
            rel="icon"
            type="image/png"
            href="/assets/images/icons/favicon.ico"
          />
        </Helmet>
        <Header />
        <main className="layout">
          {/* <Home1 /> */}
          <section className="hero-block">
            <div className="container">
              <div className="row">
                <div className="col-lg-6">
                  <h1>
                    <span>{this.state.title}</span>
                  </h1>

                  <p>{this.state.subtitle}</p>
                </div>
                <div className="videoContainer">
                  <Carousel
                    pagination={false}
                    onPrevStart={this.handlePlayVideo}
                    onNextStart={this.handlePlayVideo}
                    itemsToShow={1}
                    renderArrow={this.myarrow}
                  >
                    {this.state.home1_1s &&
                      this.state.home1_1s.map((home1, index) => {
                        return (
                          <ReactPlayer
                            id={home1._id}
                            className="video"
                            url={home1.video}
                            ref={this.videoRef}
                            controls
                          />
                        );
                      })}
                  </Carousel>
                </div>
              </div>
            </div>
          </section>

          <Home2 />
          <Home3 />
          <Testimonial />
          <Home4 />



          {/* <!-- Footer --> */}
          <FotterForm />
          <Footer />
        </main>

        {/* <!-- Back to top --> */}
        <a href="#">
          <div className="btn-back-to-top" id="myBtn">
            <span className="symbol-btn-back-to-top">
              <span className="fas fa-angle-up"></span>
            </span>
          </div>
        </a>

        {/* <!-- Modal Video 01--> */}
        {/* <div
            className="modal fade"
            id="modal-video-01"
            tabindex="-1"
            role="dialog"
            aria-hidden="true"
          >
            <div className="modal-dialog" role="document" data-dismiss="modal">
              <div
                className="close-mo-video-01 trans-0-4"
                data-dismiss="modal"
                aria-label="Close"
              >
                &times;
              </div>

              <div className="wrap-video-mo-01">
                <div className="video-mo-01">
                  <iframe
                    src="https://www.youtube.com/embed/A-xXMM_VOgY?rel=0"
                    allowfullscreen
                  ></iframe>
                </div>
              </div>
            </div>
          </div> */}
      </>
    );
  }
}

export default Home;
