import React, { Component } from 'react'
import { Modal, Button } from 'react-bootstrap';
const errMsg = {
        modalHeading: "Error occured!",
        message: "Sorry, we couldn't receive your response. Please try again later."
}

export default class FotterForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "",
            email: "",
            category: "",
            contact: "",
            other: "",
            origin: "",
            show: false,
            modalHeading: "",
            message: ""
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    handleChange = (event) => {
        this.setState({
            // Computed property names
            // keys of the objects are computed dynamically
            [event.target.name]: event.target.value,
        });
    };
    handleClose = (event) => {
        this.setState({show: false})
    }

    // handleOpen = (event) => {
    //     this.setState({show: true})
    // }

    handleSubmit = (event) => {
        let baseURL = process.env.REACT_APP_PROD_HOST;
        const { email, name, contact, category, other } = this.state;
        var that = this;

        fetch(`${baseURL}/enquiry`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                name: name,
                email: email,
                contact: contact,
                category: category,
                other: other,
                origin: window.location.href
            }),
        }).then(function (response) {
            if (response.status === 201) { 
                that.setState({
                    modalHeading: "Response saved!",
                    message: "Thanks for reaching out, we will get back to you shortly."
                })
             }
             else {
                that.setState({
                    modalHeading: errMsg.modalHeading,
                    message: errMsg.message
                })
             }
            that.setState({show: true})
            return response.json();
        }).catch(() => {
            that.setState({
                modalHeading: errMsg.modalHeading,
                message: errMsg.message
            })
            that.setState({show: true})
        });
        
        // console.log(JSON.stringify({
        //     name: name,
        //     email: email,
        //     contact: contact,
        //     category: category,
        //     other: other,
        // }))

        event.preventDefault();
        // event.target.reset();
        //alert("Responses saved!")
        this.setState({
            name: "",
            email: "",
            category: "",
            contact: "",
            other: "",
        });
        // console.log('hello');
    };

    render() {
        return (
            <section className="revolution-bg">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-7 col-lg-8">
                            <div className="revolution-block">
                                <div className="revolution-head">
                                    <h1>Join the revolution.</h1>
                                    <h3>Let’s dialogue!</h3>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-5 col-lg-4">
                            <div className="revolution-join">
                                <form onSubmit={this.handleSubmit}>
                                    <div className="form-group">
                                        <input
                                            type="text"
                                            value={this.state.name}
                                            name="name"
                                            onChange={this.handleChange}
                                            className="form-control input-field2"
                                            placeholder="Name"
                                            required
                                        />
                                    </div>

                                    <div className="form-group">
                                        <input
                                            type="number"
                                            value={this.state.contact}
                                            name="contact"
                                            onChange={this.handleChange}
                                            className="form-control input-field2"
                                            placeholder="contact number"
                                            required
                                        />
                                    </div>

                                    <div className="form-group">
                                        <input
                                            type="email"
                                            value={this.state.email}
                                            name="email"
                                            onChange={this.handleChange}
                                            className="form-control input-field2"
                                            placeholder="sample@mail.com"
                                            required
                                        />
                                    </div>

                                    <div className='form-group'  >
                                        <select required value={this.state.category} onChange={this.handleChange} className='form-control input-field2' name="category" >
                                            <option value="" selected >Please select a category</option>
                                            <option value="Parent" >Parent</option>
                                            <option value="School" >School</option>
                                            <option value="Collaborators" >Collaborators</option>
                                            <option value="Job Applicant" >Job Applicant</option>
                                            <option value="Other" >Other</option>
                                        </select>
                                    </div>

                                    {
                                        this.state.category == 'Other' ? <div className="form-group">
                                            <input
                                                type="text"
                                                value={this.state.other}
                                                name="other"
                                                onChange={this.handleChange}
                                                className="form-control input-field2"
                                                placeholder="category"
                                                required
                                            />
                                        </div> : ""
                                    }




                                    <div className="form-group m-0">
                                        <button
                                            type="submit"
                                            value="Submit"
                                            className="join-btn"
                                        >
                                            Request a call back{" "}
                                            <i className="fas fa-arrow-right"></i>
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal show={this.state.show} onHide={this.handleClose}>
                    <Modal.Header closeButton>
                    <Modal.Title><span style={{fontFamily: "Poppins, sans-serif", fontWeight: 600}}>{this.state.modalHeading}</span></Modal.Title>
                    </Modal.Header>
                    <Modal.Body><span style={{fontFamily: "Poppins, sans-serif"}}>{this.state.message}</span></Modal.Body>
                    <Modal.Footer>
                    <Button variant="primary" onClick={this.handleClose} >
                        Close
                    </Button>
                    </Modal.Footer>
                </Modal>
            </section>
        )
    }
}
