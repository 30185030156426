import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
//auth
import Register from "./auth/register";
import Login from "./auth/login";
//Components
import Home from "./components/home";
import About from "./components/about";
import SubMenuList from "./components/submenulist";
import PostList from "./components/PostList";
import Blog from "./components/blog/blog";
import Articles from "./components/blog/articles";
import BlogSingle from "./components/blog/blog_single";
import TrialPage from "./components/privateStaticPages/TrialPage";
import TabsRender from "./components/blog/tabs";
import ThankyouPage from "./components/ThankyouPage/Thankyou";
import Privatepage from "./components/privatepages/privatepage";
export default class Routing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <Router>
        {/* Auth */}
        <Route exact path="/register" component={Register} />
        <Route exact path="/login" component={Login} />
        <Route exact strict path="/" component={Home} />
        <Route exact path="/about" component={About} />
        <Route exact path="/SubMenuList/:plpID" component={SubMenuList} />
        <Route exact path="/PostList/:plpID/:menu" component={PostList} />
        <Route exact path="/blog" component={Blog} />
        <Route exact path="/articles/:plpID" component={Articles} />
        <Route exact path="/article/:_id/" component={BlogSingle} />
        <Route exact path="/TabsRender" component={TabsRender} />
        <Route exact path="/:_id" component={Privatepage} />
        <Route exact path="/PostList/trialPage/1" component={TrialPage} />
        <Route exact path="/PostList/thankyou/p" component={ThankyouPage} />
      </Router>
    );
  }
}
