import React from "react";
import axios from "axios";
import Carousel from "react-elastic-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import AliceCarousel from "react-alice-carousel";
import "../../App.css";

class Testimonial extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      testimonialData: [],
    };
  }
  componentDidMount() {
    axios
      .get(`${process.env.REACT_APP_PROD_HOST}/home/getTestimonials`)
      .then((res) => {
        const testimonialData = res.data;
        // console.log(testimonialData);
        this.setState({ testimonialData });
      });
  }
  render() {
    return (
      <section className="bg-white scrolls-space ">
        <div className="container">
          <div className="row m-0">
            <div id="owl-demo" className="owl-carousel scroll-one home5">
              <AliceCarousel
                mouseTracking
                items={1}
                disableButtonsControls={false}
                disableDotsControls={true}
              >
                {this.state.testimonialData &&
                  this.state.testimonialData.map((item, index) => {
                    return (
                      <>
                        <div className="item" key={index}>
                          <div className="row">
                            <div className="col-lg-6 slider-text">
                              <h1 className="main-head">{item.title}</h1>
                              <h5>{item.subtitle}</h5>
                              <p>{item.description}</p>
                            </div>
                            <div className="col-lg-6">
                              <img
                                // src="/assets/images/Placeholder.jpg"
                                id="HomeImg"
                                src={item.image}
                                alt="Agile Recruitech"
                              />
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })}
              </AliceCarousel>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
export default Testimonial;
