import React from "react";
import axios from "axios";
import Header from "../header";
import Footer from "../footer";
import FotterForm from "../fotterForm";
import renderHTML from "react-render-html";
import Loaderpage from "../../components/LoaderPage";
import Loader from "react-loader-spinner";
import { Link, withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import {CONSTANTS} from '../../constants';

class BlogSingle extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      description: "",
      authorName: "",
      authorLink: "",
      date: "",
      image: "",
      category: "",
      blogs: [],
      urlName: "",
      blogwithcategory: [],
      loading: false,
      loading1: false,
    };
  }
  componentDidMount() {
    const { _id } = this.props.match.params;
    // console.log(this.props.match.params);
    // console.log("something");
    axios
      .get(`${CONSTANTS.DT_SERVERS}/blog/update_blog1/${_id}`)
      .then((res) => {
        //console.log("response", res);
        const post = {
          title: res.data[0].title,
          description: res.data[0].description,
          category: res.data[0].category,
          image: res.data[0].image,
          authorName: res.data[0].authorName,
          authorLink: res.data[0].authorLink,
          date: res.data[0].date,
          urlName: res.data[0].urlName,
        };
        this.setState({
          title: post.title,
          description: post.description,
          category: post.category,
          authorLink: post.authorLink,
          authorName: post.authorName,
          image: post.image,
          date: post.date,
          urlName: post.urlName,
          loading: true,
        });
      });

    axios.get(`${CONSTANTS.DT_SERVERS}/blog/Blog1s?page=0&limitPostsBy=6`).then((res) => {
      const blogs = res.data.documents.reverse();
      //console.log(blogs);
      this.setState({ blogs, loading1: true });
    });

    const { category } = this.props.match.params;
    axios
      .get(
        `${CONSTANTS.DT_SERVERS}/blog/blogwithcategory/${category}`
      )
      .then((res) => {
        const blogs = res.data.reverse();
        // console.log(blogs);
        this.setState({ blogs, loading1: true });
      });
  }

  render() {
    return (
      <>
        {this.state.loading ? (
          <>
            <Header />

            <main className="layout">
              <section className="blog-space2">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-10">
                      <div className="blog-details">
                        <>
                          <p>
                            <a href="/blog">
                              <i className="fas fa-arrow-left font-13"></i> All
                              Articles
                            </a>
                          </p>
                          <span className="SingleBlogAuthorNameContainer">
                            <h1 className="main-head blog-single-headspce">
                              {this.state.title}
                            </h1>
                            <span className="SingleBlogAuthorName">
                              <a href={this.state.authorLink} target="_blank">
                                <p id="ArticlesAuthorNameSingle">
                                  {this.state.authorName}
                                </p>
                              </a>

                              <p>
                                {" "}
                                {new Date(this.state.date).getDate() +
                                  " " +
                                  new Date(this.state.date).toLocaleDateString(
                                    undefined,
                                    {
                                      month: "long",
                                    }
                                  ) +
                                  ", " +
                                  new Date(this.state.date).getFullYear()}
                              </p>
                            </span>
                          </span>

                          <div className="blog-single-img">
                            {renderHTML(this.state.description)}
                          </div>
                        </>
                      </div>
                    </div>
                  </div>

                  <div className="blog_tabs">
                    <h1 className="txt-left sub-head">Recent Articles</h1>
                    <div className="row">
                      {this.state.loading1 ? (
                        <>
                          {this.state.blogs.map((blog, index) => {
                            return (
                              <>
                                <div
                                  className="col-lg-4 col-md-6 col-sm-6"
                                  key={index}
                                >
                                  <div className="blog-artcle-box">
                                    <div className="content">
                                      <div className="content-overlay"></div>
                                      <div className="blog-img">
                                        <img
                                          src={blog.image}
                                          className="img-fluid"
                                        />
                                      </div>
                                      <div className="content-details fadeIn-top">
                                        <p className="read-article">
                                          Read Article
                                        </p>
                                        <a
                                          href={`/article/${blog.urlName}`}
                                          className="team-social blog-link"
                                        >
                                          <i className="fas fa-arrow-right"></i>
                                        </a>
                                      </div>
                                    </div>
                                    <div className="blog-data">
                                      <p>
                                        <span className="txt-left">
                                          {blog.category}
                                        </span>
                                        <span className="txt-right">
                                          {new Date(blog.date).getDate() +
                                            " " +
                                            new Date(
                                              blog.date
                                            ).toLocaleDateString(undefined, {
                                              month: "long",
                                            }) +
                                            ", " +
                                            new Date(blog.date).getFullYear()}
                                        </span>
                                      </p>
                                      <h5>{blog.title}</h5>
                                      <span>
                                        <p id="ArticlesAuthorName">
                                          {blog.authorName
                                            ? blog.authorName
                                            : ""}
                                        </p>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </>
                            );
                          })}
                        </>
                      ) : (
                        <div style={{ marginLeft: "500px" }}>
                          {" "}
                          <Loader
                            type="Circles"
                            color="#0029ff"
                            height={100}
                            width={100}
                            timeout={3000} //3 secs
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </section>
              <FotterForm />
              <Footer />
            </main>

            <div className="btn-back-to-top" id="myBtn">
              <span className="symbol-btn-back-to-top">
                <span className="fas fa-angle-up"></span>
              </span>
            </div>
          </>
        ) : (
          <div className="divLoader1">
            <Loaderpage />
          </div>
        )}
      </>
    );
  }
}

export default BlogSingle;
