import Routing from "./routing";
function App() {
  return (
    <>
      <Routing></Routing>
    </>
  );
}

export default App;
