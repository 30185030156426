import React from "react";

const CustomComponent = (props) => {
  return (
    <div>
      <h3 style={{ color: "gray" }}>{props.content}</h3>
    </div>
  );
};

export default CustomComponent;
