import React from "react";
import axios from "axios";
import "../App.css";

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    axios
      .get(`${process.env.REACT_APP_PROD_HOST}/private/privatepages`)
      .then((res) => {
        const PrivatePages = res.data;
        // console.log(PrivatePages);
        this.setState({ PrivatePages });
      });
  }

  render() {
    return (
      <footer>
        <div className="footerContainer">
          <div className="row1">
            <span>
              <h3>AGILE RECRUITECH</h3>
              <h4>Worldwide</h4>
            </span>
            <span>
              <p id="phone">+91 7207001400</p>
            </span>
          </div>
          <div className="row row mx-0 pt-2">
            <div>
              Agile RecruiTech LLP is a sister concern organization of <strong title="DeepThought EduTech Ventures Pvt. Ltd.">DeepThought. &nbsp;</strong>
              We setup Tech Enabled Systems that automate recruitment + organizational alignment besids employee self-development
            </div>
          </div>
          <div className="row row mx-0">
            <div>
                
            </div>
          </div>
          {/* <div className="row2">
            <a href="#">
              <p>Contact Us</p>
            </a>
            <a href="https://deepthought.education/privacystatement">
              <p>Privacy Policy</p>
            </a>
            <a href="https://deepthought.education/returnpolicy">
              <p>Returns Policy</p>
            </a>
            <a href="https://deepthought.education/termsofservice">
              <p id="terms">Terms of Service</p>
            </a>
            <a href="https://deepthought.education/careers">
              <p>Careers</p>
            </a>
          </div> */}
        </div>

        <div className="bg12">
          <div className="container size-h-4 p-tb-15 bdr-top-fotter">
            <div className="row">
              <span className="f1-s-1 cl0 txt-left col-md-6 p-0 line-copy footerCopy">
                © Copyright 2023. All Rights Reserved.
              </span>
              <span className="f1-s-1 cl0 txt-right col-md-6 p-0 footerCopy">
                <ul className="social-links">
                  <li>
                    <a href="#" target="_blank">
                      <i className="fab fa-whatsapp footerIcon"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.instagram.com/deepthoughtedutechventures/?hl=en"
                      target="_blank"
                    >
                      <i className="fab fa-instagram footerIcon"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.facebook.com/DeepThoughtEduTech"
                      target="_blank"
                    >
                      <i className="fab fa-facebook-f footerIcon"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://in.linkedin.com/company/deepthoughtedutech"
                      target="_blank"
                    >
                      <i className="fab fa-linkedin footerIcon"></i>
                    </a>
                  </li>
                </ul>
              </span>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
