import React from "react";

import axios from "axios";
import Loaderpage from "../components/LoaderPage";
import { Link, withRouter } from "react-router-dom";
import "../App.css";

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menus: [],
      submenus: [],
      selectedMenuName: "",
      bool: false,
      sideBar: false,
    };
    // console.log(this.props);
    this.handleSidebar = this.handleSidebar.bind(this);
  }

  componentDidMount() {
    axios.get(`${process.env.REACT_APP_PROD_HOST}/admin/menus`).then((res) => {
      const menus = res.data;
      // console.log(menus);
      this.setState({ menus });
    });

    axios
      .get(
        `${process.env.REACT_APP_PROD_HOST}/blog/update_blog/608a5f3bb23d99001525b0ce`
      )
      .then((res) => {
        // console.log(res.data);
        const blog = {
          url: res.data.url,
        };
        // console.log(blog.url);
        this.setState({
          url: blog.url,
        });
      });
  }
  handleSidebar() {
    this.setState({
      sideBar: !this.state.sideBar,
    });
  }
  handleClick = (selectedMenuName) => {
    // console.log(selectedMenuName);
    axios
      .get(
        `${process.env.REACT_APP_PROD_HOST}/admin/submenuvalues/${selectedMenuName}`
      )
      .then((res) => {
        const submenus = res.data;
        this.setState({
          submenus: submenus,
          selectedMenuName: selectedMenuName,
          loading: true,
          bool: !this.state.bool,
        });
      });
  };
  render() {
    return (
      <header>
        <div className="container-menu-desktop">
          <div className="wrap-header-mobile">
            <div className="logo-mobile">
              <a href="/">
                <img src="/assets/images/logo/logo.svg" alt="IMG-LOGO" />
              </a>
            </div>
            <button
              onClick={this.handleSidebar}
              className={`navToggle ${this.state.sideBar ? "open" : null}`}
            >
              <span />
              <span />
              <span />
            </button>
          </div>

          <nav>
            <ul
              className="mainNav"
              style={this.state.sideBar ? { transform: "translateX(0)" } : null}
            >
              {this.state.menus &&
                this.state.menus.map((menu, index) => {
                  return (
                    <>
                      <li key={index}
                        id="liOfMenu"
                        onClick={this.handleClick.bind(this, menu.menu)}
                      >
                        <span>
                          <a
                            href="#"
                            className={
                              this.isPathActive(`/SubMenuList/${menu.menu}`)
                                ? "active"
                                : null
                            }
                            className="mainNavLink"
                          >
                            {menu.menu}
                          </a>
                          <i className="fa fa-angle-down"></i>
                        </span>

                        {this.state.bool
                          ? this.state.submenus.length > 0 &&
                            this.state.submenus.map(
                              (item) =>
                                item.menu === menu.menu && (
                                  <Link
                                    onClick={this.handleSidebar}
                                    to={`/PostList/${item.submenu}/${menu.menu}`}
                                    className={
                                      this.isPathActive(
                                        `/PostList/${item.submenu}/${menu.menu}`
                                      )
                                        ? "active"
                                        : null
                                    }
                                    className="submenus"
                                  >
                                    <p id="pTagSubmenu">
                                      <i className="fa fa-circle subMenuIcon">
                                        {" "}
                                      </i>
                                      {item.submenu}
                                    </p>
                                  </Link>
                                )
                            )
                          : ""}
                      </li>
                    </>
                  );
                })}

              <li>
                <Link to="/blog" className="mainNavLink">
                  Articles
                </Link>
              </li>
              <li>
                <Link to="/about" className="mainNavLink">
                  About Us
                </Link>
              </li>
              <li>
                <Link to="/login" className="mainNavLink">
                  Login
                </Link>
              </li>
            </ul>
          </nav>

          <div className="wrap-logo container">
            {/* <!-- Logo desktop -->*/}
            <div className="logo">
              <a href="/">
                <img src="/assets/images/logo/logo.svg" alt="LOGO" />
              </a>
            </div>

            {/* <!-- Banner --> */}

            <div className="wrap-main-nav">
              <div className="main-nav">
                <nav className="menu-desktop">
                  <ul className="main-menu">
                    {this.state.menus &&
                      this.state.menus.map((menu, index) => {
                        return (
                          <li
                            className="mega-menu-item"
                            contentEditable={false}
                          >
                            <a
                              href="#"
                              className={
                                this.isPathActive(
                                  `/PostList/${this.props.match.params.plpID}/${menu.menu}`
                                )
                                  ? "active"
                                  : null
                              }
                            >
                              {menu.menu}
                            </a>
                            <SubMenuView MenuName={menu.menu} />{" "}
                          </li>
                        );
                      })}

                    <li>
                      <a
                        href="/blog"
                        className={this.isPathActive("/blog") ? "active" : null}
                      >
                        Articles
                      </a>
                    </li>
                    <li>
                      <a
                        href="/about"
                        className={
                          this.isPathActive("/about") ? "active" : null
                        }
                      >
                        About Us{" "}
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>

            <div className="login-search-desktop">
              <span className="login">
                <a href="/login" className="btn-small">
                  Login
                </a>
              </span>
            </div>
          </div>
        </div>
      </header>
    );
  }
  isPathActive(path) {
    return this.props.location.pathname.startsWith(path);
  }
}

class SubMenuView extends React.Component {
  constructor(props) {
    super(props);
    this.state = { loading: false };
  }

  componentDidMount() {
    let selectedMenuName = this.props.MenuName;
    axios
      .get(
        `${process.env.REACT_APP_PROD_HOST}/admin/submenuvalues/${selectedMenuName}`
      )
      .then((res) => {
        const submenus = res.data;
        this.setState({ submenus, loading: true });
      });
  }

  render() {
    var subSubMenuList =
      this.state.submenus &&
      this.state.submenus.map((data, index) => {
        return (
          <div className="col-md-6 sub-space" key={index}>
            <h1>
              {this.state.loading ? (
                <img id="SubmenuImg" src={data.image} />
              ) : (
                <div className="divLoader1">
                  <Loaderpage />
                </div>
              )}

              <a href={`/PostList/${data.submenu}/${data.menu}`}>
                {data.submenu}
              </a>
            </h1>
            <p>{data.description}</p>
          </div>
        );
      });

    return (
      <>
        <div className="sub-mega-menu">
          <div className="row col-12 p-0 m-0">{subSubMenuList} </div>
        </div>
      </>
    );
  }
}
export default withRouter(Header);
