import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import "./TrialPage.css";
import Img_1 from "./1.svg";
import Img_2 from "./2.svg";
import Img_3 from "./3.svg";
import Img_4 from "./4.svg";
import Img_5 from "./5.svg";
import Img_6 from "./6.svg";
import png_1 from "./1.png";
import png_2 from "./2.png";
import jpg_3 from "./3.jpg";
import tl_1 from "./thought_leaders_1.png";
import tl_2 from "./thought_leaders_2.png";
import tl_3 from "./thought_leaders.png";
import comas from "./quotes_icon.svg";
import Header from "../header";
import Footer from "../footer";

const TrialPage = () => {
  return (

    // Trial Page component rendering the static page concerned with lead genreation and call to actions

    <>
      <div className="landing section">
        <div className="container banner text-light pt-5" id="form">
          <div className="row">
            <div className="col-lg-8">
              <h1 className="display-4 text-yellow fw-900">
                Nurturing Thought Leaders out of Children
              </h1>
              <p className="lead border-left mt-5">
                We create leaders for the society and the economy <br />
                We bring PhD level rigor and corporate rigor to the school level
                <br />
                Our processes and research do to education what Tesla did to
                automobiles <br />
                We reposition education - We are India's answer to Tesla!
              </p>
            </div>
            <div className="col-lg-4">
              <form action='https://crm.zoho.in/crm/WebToLeadForm' name="WebToLeads229130000000246019" method='POST'
                onSubmit='javascript:document.charset="UTF-8";'
              >
                <input type='text' hidden={true} name='xnQsjsdp'
                  value='48a1f9c03dd46a45124498c2a83d03897397f6670825cdaac98eb1c05b9cd10b'></input>
                <input type='hidden' name='zc_gad' id='zc_gad' value=''></input>
                <input type='text' hidden={true} name='xmIwtLD'
                  value='07e73ee4f11222dc6a9e9c2664d255d4ad0894840e52c9d7c878b6b03560f590'></input>
                <input type='text' hidden={true} name='actionType' value='TGVhZHM='></input>
                <input type='text' hidden={true} name='returnURL'
                  value='https&#x3a;&#x2f;&#x2f;deepthought.education&#x2f;PostList&#x2f;thankyou&#x2f;p'></input>
                <input type='text' hidden={true} id='ldeskuid' name='ldeskuid'></input>
                <input type='text' hidden={true} id='LDTuvid' name='LDTuvid'></input>

                <div class='zcwf_row InputFields'>
                  <div class='zcwf_col_lab' ><label for='Last_Name'>Full Name<span
                  >*</span></label></div>
                  <div class='zcwf_col_fld'><input placeholder="Enter Full Name. . . ." type='text' id='Last_Name' name='Last_Name' maxlength='80'></input>
                    <div class='zcwf_col_help'></div>
                  </div>
                </div>
                <div class='zcwf_row InputFields'>
                  <div class='zcwf_col_lab'><label for='First_Name'>Child's
                    Name<span >*</span></label></div>
                  <div class='zcwf_col_fld'><input placeholder="Enter Child's Name. . . ." type='text' id='First_Name' name='First_Name' maxlength='40'></input>
                    <div class='zcwf_col_help'></div>
                  </div>
                </div>
                <div class='zcwf_row InputFields'>
                  <div class='zcwf_col_lab' ><label for='Email'>Email<span
                  >*</span></label></div>
                  <div class='zcwf_col_fld'><input placeholder="Enter Email. . . ." type='text' ftype='Email' id='Email' name='Email' maxlength='100'></input>
                    <div class='zcwf_col_help'></div>
                  </div>
                </div>
                <div class='zcwf_row InputFields'>
                  <div class='zcwf_col_lab' ><label for='Phone'>Phone<span
                  >*</span></label></div>
                  <div class='zcwf_col_fld'><input placeholder="Enter Phone number. . . ." type='text' id='Phone' name='Phone' maxlength='30'></input>
                    <div class='zcwf_col_help'></div>
                  </div>
                </div>
                <div class='zcwf_row InputFields'>
                  <div class='zcwf_col_lab' ><label for='LEADCF3'>Grade/Class<span
                  >*</span></label></div>
                  <div class='zcwf_col_fld'><select class='zcwf_col_fld_slt' id='LEADCF3' name='LEADCF3'>
                    <option value='-None-'>-None-</option>
                    <option selected value='III&#x20;-&#x20;V'>III - V</option>
                    <option value='VI&#x20;-&#x20;VII'>VI - VII</option>
                    <option value='VIII&#x20;-X'>VIII -X</option>
                    <option value='XI&#x20;-XII'>XI -XII</option>
                  </select>
                    <div class='zcwf_col_help'></div>
                  </div>
                </div>
                {/* <div class='zcwf_row wfrm_fld_dpNn'>
                  <div class='zcwf_col_lab'><label for='Lead_Source'>Lead
                    Source</label></div>
                  <div class='zcwf_col_fld'><select class='zcwf_col_fld_slt' id='Lead_Source' name='Lead Source'>
                    <option value='-None-'>-None-</option>
                    <option value='Advertisement'>Advertisement</option>
                    <option value='Cold&#x20;Call'>Cold Call</option>
                    <option value='Employee&#x20;Referral'>Employee Referral</option>
                    <option value='External&#x20;Referral'>External Referral</option>
                    <option value='Online&#x20;Store'>Online Store</option>
                    <option value='Partner'>Partner</option>
                    <option value='Public&#x20;Relations'>Public Relations</option>
                    <option value='Sales&#x20;Email&#x20;Alias'>Sales Email Alias</option>
                    <option value='Seminar&#x20;Partner'>Seminar Partner</option>
                    <option value='Internal&#x20;Seminar'>Internal Seminar</option>
                    <option value='Trade&#x20;Show'>Trade Show</option>
                    <option value='Web&#x20;Download'>Web Download</option>
                    <option selected value='Web&#x20;Research'>Web Research</option>
                    <option value='Chat'>Chat</option>
                    <option value='Twitter'>Twitter</option>
                    <option value='Facebook'>Facebook</option>
                    <option value='Google&#x2b;'>Google&#x2b;</option>
                  </select>
                    <div class='zcwf_col_help'></div>
                  </div>
                </div> */}
                <div class='zcwf_row '>
                  <div class='zcwf_col_lab'></div>
                  <div class='zcwf_col_fld SubmitAndReset' ><input type='submit' id='formsubmit' class='formsubmit zcwf_button' value='Submit'
                    title='Submit' /><input type='reset' class='zcwf_button' name='reset' value='Reset' title='Reset' />
                  </div>
                </div>

              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="container section pt-5">
        <div className="">
          <h1 className="display_5 text-center ">What we do ?</h1>
        </div>
        <div className="row">
          <div className="col-lg-4 d-flex">
            <div className="dt-card">
              <img className="icon" src={Img_1} alt="" />
              <h4 className="pb-3 pt-4 text-primary fw-700">
                Socratic Dialogues
              </h4>
              <p>
                In line with the DeepThought vision of 'a world where everyone
                is a researcher’, we organize Socratic Dialogue competitions
                that reward collaborative questions and interesting wrong
                answers. Students rub shoulders with CEOs, business leaders, and
                researchers, dialoguing with the rigor of a researcher and the
                integrity of a leader on diverse topics.
              </p>
            </div>
          </div>
          <div className="col-lg-4 d-flex">
            <div className="dt-card">
              <img className="icon" src={Img_2} alt="" />
              <h4 className="pb-3 pt-4 text-primary fw-700">
                Young CEO Classes
              </h4>
              <p>
                DeepThought's Young CEO classes nurture leaders for the society,
                and the economy! The students are nudged to develop their own
                conceptualization of management principles and Thought
                Leadership. The students develop a 10Xer mindset and acquire
                leadership qualities that help them take charge of their own
                self and the world.
              </p>
            </div>
          </div>
          <div className="col-lg-4 d-flex">
            <div className="dt-card">
              <img className="icon" src={Img_3} alt="" />
              <h4 className="pb-3 pt-4 text-primary fw-700">
                Maths/Science Classes
              </h4>
              <p>
                DeepThought classes offer a unique, research-focussed approach
                towards learning. DeepThought's educational environment and
                processes enable students to construct a concept instead of
                passively consuming information. This also enables them to
                utilize the self-conceived concepts of Science and Math in
                real-life problem solving and personality evolution.
              </p>
            </div>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-lg-4 d-flex">
            <div className="dt-card">
              <img className="icon" src={Img_4} alt="" />
              <h4 className="pb-3 pt-4 text-primary fw-700">
                IITJEE/NEET Classes
              </h4>
              <p>
                IITJEE/NEET preparation at DeepThought is not a painful process,
                but a journey towards success through self-actuated learning.
                Our IITJEE/NEET classes combine the thought processes of world
                geniuses in the form of scientific learning methods like UX
                based worksheets, mind-maps and smart thread-builders, to aid
                problem-solving and metacognition.
              </p>
            </div>
          </div>
          <div className="col-lg-4 d-flex">
            <div className="dt-card">
              <img className="icon" src={Img_5} alt="" />
              <h4 className="pb-3 pt-4 text-primary fw-700">QuizApp</h4>
              <p>
                DeepThought QuizApp converts knowledge of several domains like
                STEM, Social Studies and Leadership into actionable wisdom. The
                DeepQuizzes help learners to internalize the fundamental
                principles of different disciplines so that the learners are
                able to extrapolate the wisdom to real-life problem solving.
              </p>
            </div>
          </div>
          <div className="col-lg-4 d-flex">
            <div className="dt-card">
              <img className="icon" src={Img_6} alt="" />
              <h4 className="pb-3 pt-4 text-primary fw-700">Mothers' School</h4>
              <p>
                Mothers are taught basic curriculum topics through discussions.
                The idea is to evolve a Mother's idea of education and to
                empower a mother to engage in dinner table discussions. Mothers
                are asked interesting questions to think about. Mothers see why
                education is more about what you discover, than about what the
                teacher covers.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="container section thought-section">
        <div className="educationAs">
          <h1 className="display-5 text-center pb-5">
            Education as
            <span className="fw-900 text-primary"> Thought </span> Leadership
          </h1>
        </div>
        <div className="row align-items-center">
          <div className="col-lg-6 pr-5">
            <img className="" src={tl_1} alt="" />
          </div>
          <div className="col-lg-6">
            <div className="">
              <h2 className="pb-3 pt-4 text-primary fw-900 display_e">
                Education
              </h2>
              <p className="border-left">
                In line with the DeepThought vision of 'a world where everyone
                is a researcher’, we organize Socratic Dialogue competitions
                that reward collaborative questions and interesting wrong
                answers. Students rub shoulders with CEOs, business leaders, and
                researchers, dialoguing on diverse topics with the rigor of a
                researcher and integrity of a leader.
              </p>
              <div className="btn btn-secondary mt-3">Know More</div>
            </div>
          </div>
        </div>
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="">
              <h2 className="pb-3 pt-4 text-primary fw-900 display_e">
                Leadership
              </h2>
              <p className="border-left">
                DeepThought's Young CEO classes nurture leaders for the society,
                and the economy! The students are nudged to develop their own
                conceptualization of management principles and Thought
                Leadership. The students develop a 10Xer mindset and acquire
                leadership qualities that help them take charge of their own
                self and the world.
              </p>
              <div className="btn btn-secondary mt-3">Know More</div>
            </div>
          </div>
          <div className="col-lg-6 pr-5">
            <img className="" src={tl_2} alt="" />
          </div>
        </div>
        <div className="row align-items-center">
          <div className="col-lg-6 pr-5">
            <img className="" src={tl_3} alt="" />
          </div>
          <div className="col-lg-6">
            <div className="">
              <h2 className="pb-3 pt-4 text-primary fw-900 display_e">
                Technology
              </h2>
              <p className="border-left">
                DeepThought classes offers a unique, research-focussed approach
                towards learning. DeepThought's educational environment and
                processes enable students to construct a concept instead of
                passively consuming information. This also enables them to
                utilize the self-conceived concepts of Science and Math in
                real-life problem solving and personality evolution.
              </p>
              <div className="btn btn-secondary mt-3">Know More</div>
            </div>
          </div>
        </div>
      </div>

      <div className="about">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <h2 className="display-5 fw-700 pb-4 about_l">About</h2>
              <p>
                Let’s awaken the world into a heaven of freedom where everyone
                is a researcher, where every decision is thought through, where
                knowledge creators are respected!
              </p>
              <p>
                "DeepThought nurtures Thought Leaders who can extrapolate out of
                textual contents into <br />
                <li>(i) interdisciplinary</li>
                <li>(ii) cross-functional</li>
                <li>
                  (iii) real-life problem solving and socio-economic value
                  creation."
                </li>
              </p>

              <p className="pt-4">
                "to lead the world into a heaven of freedom where everyone is a
                researcher, where decision-making is thought through, where
                knowledge creators are respected"
              </p>
            </div>
            <div className="col-lg-6">
              <iframe
                width="600"
                height="380"
                src="https://www.youtube.com/embed/wPU_W_bI5bI"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
          </div>
        </div>
      </div>

      <div className="testimonials secondary-bg mt-5">
        <div className="container">
          <div className="">
            <h1 className="display-5 text-center fw-900 pt-5 testi_l">
              Testimonials
            </h1>
          </div>
          <div id="demo" className="carousel slide" data-ride="carousel">
            <div className="carousel-inner">
              <div className="carousel-item active">
                <div className="carousel-caption">
                  <p>
                    “I am amazed at how such complicated topics were discussed
                    (by students) with ease, I mean if I put these concepts in a
                    mind map I can fill up a full board. You guys took up of
                    60-70% of ideas that were put forth which in itself is quite
                    a task.“
                  </p>
                  <img src={png_1} />
                  <div id="image-caption">Mr. Ravi Devulapalli</div>
                  <p>Technology Leader</p>
                </div>
              </div>
              <div className="carousel-item">
                <div className="carousel-caption">
                  <p>
                    "The fun thing about DeepThought IITJEE classes is that many
                    of the learning processes are gamified, which is nice,
                    because you are constantly and willingly on your toes, and
                    you are also at a good level of comfort, which makes the
                    whole competitive exam preparation process pretty exciting
                    and interesting."
                  </p>
                  <img src={jpg_3} className="img-fluid" />
                  <div id="image-caption">Swetha Reddy</div>
                  <p>Student and IITJEE Aspirant</p>
                </div>
              </div>
              <div className="carousel-item">
                <div className="carousel-caption">
                  <p>
                    "She was a child getting 70s in the 8th and 9th, to getting
                    90s in the 10th. I think that was like a real big change for
                    her."
                  </p>
                  <img src={png_2} className="img-fluid" />
                  <div id="image-caption">Ashwina Garg</div>
                  <p>Parent of Arushi Garg</p>
                </div>
              </div>
            </div>
            <a className="carousel-control-prev" href="#demo" data-slide="prev">
              <i className="fa fa-arrow-left"></i>
            </a>
            <a className="carousel-control-next" href="#demo" data-slide="next">
              <i className="fa fa-arrow-right"></i>
            </a>
          </div>
        </div>
      </div>
      <div className="cta primary-bg last_cta ">
        <div className="section landing">
          <div className="container text-center text-light">
            <h1 className="text-yellow testi_l">Join the revolution</h1>
            <h2 className="text-light pt-3 testi_ld">Let’s dialogue</h2>
            <p className="display-6">We are India’s Answer to Tesla!</p>
            <a href="#form" className="btn btn-white mt-3">
              Request a call back
            </a>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default TrialPage;